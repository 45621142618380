$green: #39E489;

li.ant-pagination-item.ant-pagination-item-active {
  color: #39e489 !important; }

.ant-tooltip .ant-tooltip-inner p a {
  color: #695EE8 !important;
  margin-left: 0 !important; }

.anticon-question-circle {
  margin-bottom: 8px !important;
  margin-left: 9px !important; }

.ant-form-item-label .anticon.anticon-question-circle.ant-form-item-tooltip {
  position: relative;
  bottom: -4px; }

.flex-center {
  justify-content: center;
  display: flex;
  align-items: center;
  &-important {
    justify-content: center !important;
    display: flex !important;
    align-items: center !important; } }

.shadow-ml {
  box-shadow: 0 5px 15px rgba(9, 14, 37, 0.0951705) !important; }

.pointer {
  cursor: pointer !important; }

.border-bottom {
  border-bottom: 1px solid #262D42; }

.align-center {
  align-items: center !important; }

.standardCard {
  box-shadow: 0 5px 15px rgba(9, 14, 37, 0.0951705) !important;
  border-radius: 5px;
  overflow: hidden;
  padding: 20px; }

.text-green {
  color: #39E489 !important; }

.mr-20 {
  margin-right: 20px !important; }

.ml-20 {
  margin-left: 20px !important; }

.pl-20 {
  padding-left: 20px !important; }

.mr-10 {
  margin-right: 10px !important; }

.ml-10 {
  margin-left: 10px !important; }

.pr-10 {
  padding-right: 10px !important; }

.pl-10 {
  padding-left: 10px !important; }

.flex-wrap {
  flex-wrap: wrap; }

.mb-20 {
  margin-bottom: 20px !important; }

.mt-20 {
  margin-top: 20px !important; }

.fs-60 {
  font-size: 60px !important; }

.fs-15 {
  font-size: 15px !important; }

.fs-14 {
  font-size: 14px !important; }

.p-absolute, .position-absolute {
  position: absolute !important; }

.p-relative, .position-relative {
  position: relative !important; }

.top-0 {
  top: 0 !important; }

.top-10 {
  top: 10px !important; }

.top-5 {
  top: 5px !important; }

.right-0 {
  right: 0 !important; }

.scrollable {
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px; }
  &::-webkit-scrollbar-thumb {
    background: #39E489;
    border-radius: 0px; }
  &::-webkit-scrollbar-thumb:hover {
    background: #B3AFB3; }
  &::-webkit-scrollbar-track {
    background: #F0F0F0;
    border-radius: 0px;
    box-shadow: inset 0px 0px 0px 0px #F0F0F0; } }

.download {
  height: 32px !important;
  width: 100% !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  border-radius: 3px !important;
  border: 1px solid #00a0ff !important;
  background-color: transparent !important;
  color: #00a0ff !important;
  text-align: center !important; }

.radius-sm {
  border-radius: 3px !important; }

.hidden {
  overflow: hidden; }

.justify-center {
  justify-content: center !important; }

.ant-select-selection-placeholder {
  display: flex;
  align-items: center; }

.btn-bordered-green {
  border: 1px solid #39E489 !important;
  font-weight: 500 !important;
  color: #24D677 !important;
  padding: 7px 25px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px !important;
  border-radius: 3px !important; }

.ant-select-multiple .ant-select-selector {
  overflow: hidden;
  .ant-select-selection-item {
    margin: 0 8px 5px 0 !important; } }

.fw-500 {
  font-weight: 500 !important; }

.justify-between {
  justify-content: space-between; }

.flex-right {
  display: flex;
  justify-content: flex-end; }

.bg-green {
  background: #39E489 !important; }

.bg-purple {
  background: #695EE8 !important; }

.bg-sky {
  background: #00A0FF !important; }

.fw-400 {
  font-weight: 400 !important; }

.justify-around {
  justify-content: space-around !important; }

.btnGreen {
  background: #39E489 !important;
  text-align: center;
  border-radius: 3px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #262D42 !important;
  border: none;
  font-weight: 600;
  width: 100%;
  &:hover, &:focus {
    color: #181c29 !important; }
  &.important {
    background: #39E489 !important;
    text-align: center !important;
    border-radius: 3px !important;
    height: 36px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #262D42;
    font-weight: 600 !important; } }

.btn-violet-inverted-important {
  border-color: #695EE8 !important;
  color: #695EE8 !important; }

.btn-violet-important {
  background: #695EE8 !important;
  color: #fff !important;
  &:hover, &:focus {
    background: #6359d4 !important;
    border: 1px solid #6359d4 !important; }
  &:disabled {
    background: #928bdf !important;
    border: 1px solid #928bdf !important; } }

.button-dark, .btn-dark {
  border-color: #262D42 !important;
  color: #262D42 !important; }

.custom-button-blue, .custom-btn-blue {
  color: #fff !important;
  background: #00A0FF !important;
  border-color: #00A0FF !important; }

iframe[style="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; border: none; z-index: 2147483647;"] {
  display: none !important; }

.bg-blue {
  background: #00A0FF !important; }

.gap-20 {
  grid-gap: 20px !important;
  gap: 20px !important; }

.uploadDefaultGM {
  .ant-upload-list-picture-card-container, .ant-upload-select-picture-card {
    width: 100% !important;
    height: 180px;
    .ant-upload-list-item {
      border: none !important;
      padding: 0 !important; } } }

.ant-form-item.formItemOne {
  flex-direction: column;
  text-align: left !important;
  align-items: flex-start;
  justify-content: flex-start;

  .ant-form-item-required::before {
    display: none !important; }
  .ant-form-item-control {
    width: 100%; } }

.grid-50-50 {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.view-file-btn {
  border: 1px solid $green !important;
  color: #565E7C !important;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 5px;
  background-color: #39E48940 !important;
  font-size: 14px !important;
  img {
    margin-right: 6px !important;
    position: relative;
    bottom: 1px; }
  span {
    font-size: 13px !important; } }

.p-relative {
  position: relative !important; }

.relative {
  position: relative !important; }

.text-grey {
  color: #A1ACC2 !important; }

.btn-black {
  text-align: center !important;
  display: flex;
  align-items: center;
  width: 130px !important;
  height: 36px !important;
  background-color: #fff !important;
  font-weight: 500;
  border-radius: 3px !important;
  color: #262d42 !important;
  font-weight: 500;
  border: thin solid #262d42 !important;
  margin-top: 10px;
  margin-bottom: 20px;
  svg {
    position: relative;
    top: 3px; } }

.text-black {
  color: #000 !important; }

.border-1 {
  height: 1px;
  width: 100%;
  background: #CAD7E6; }

body .ant-picker.ant-picker-disabled {
  background: #f0f3f4 !important; }

body .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background: #f0f3f4 !important; }

.one-line {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important; }

.m-w-250 {
  max-width: 250px !important; }
