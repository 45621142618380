// Import mixins
@import 'components/mixins.scss';

.ant-notification {
  max-width: calc(100vw - 48px);
  border-radius: 5px;
  right: 0 !important;
  top: auto !important;
  bottom: 24px !important;
  & .ant-notification-notice.success,
  .ant-notification-notice-closable.success {
    background: rgba(57, 228, 137, 0.1) !important;
  }
  &-notice-message {
    font-size: 14px;
    line-height: 23px;
    color: #262d42 !important;
  }
  &-close-icon {
    svg {
      fill: #262d42 !important;
    }
  }
  // & .ant-notification-notice,
  // .ant-notification-notice-closable {
  //   color: red !important;
  // }
}

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-notification {
    &-notice-message {
      color: $white;
    }
    &-close-icon {
      svg {
        fill: $white;
      }
    }
  }
}
