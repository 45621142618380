@import 'components/mixins.scss';

.row {
  margin-top: 1.5rem !important;
}
.headertitle {
  font-family: 'Mukta', sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: #262d42;
  opacity: 0.6;
}
.card {
  display: flex !important;
  width: 100% !important;
  background: #fff;
  padding: 1rem;
  box-shadow: 0 5px 15px rgba(9, 14, 37, 0.0951705) !important;
  border-radius: 5px !important;
  & .status {
    position: absolute !important;
    margin-top: -10px !important;
  }
  & span {
    & i:before {
      color: #fe3d2e !important;
    }
  }
}
.headerimage {
  border-radius: 6px !important;
  border: 2px solid #fe3d2e !important;
  background-color: #fe3d2e;
}
.kit__utils__avatar {
  & img {
    width: 40px;
    margin-right: 1rem;
  }
}
.btnWithAddon {
  overflow: hidden;
  position: relative;
  padding-left: rem(50) !important;
  border: none;

  .btnAddon {
    position: absolute;
    z-index: 1;
    top: -1px;
    left: -1px;
    bottom: -1px;
    background-color: rgba($white, 0.2);
    width: rem(40);
  }

  .btnAddonIcon {
    font-size: rem(16);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.imgDefault {
  width: 29px;
}
.imgHover {
  display: none;
}
.btnArrow {
  margin: auto 0 12px 0;
  padding: 0;
}
.btnArrow:hover {
  .imgHover {
    display: inline !important;
  }
  .imgDefault {
    display: none !important;
  }
}

.headerStatus {
  float: right;
  display: flex;
  justify-content: flex-end;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  color: #262d42;
}

.miniTabs {
  border: 1px solid #39e489;
  border-radius: 3px;
  display: flex;
}

.tabLeft{
  border-right: #39e489 1px solid;
  padding: 10px 18px;
  color: rgb(38, 45, 66) !important;
  display: flex;
}

.tabRight{
  padding: 10px 18px;
  // padding: 10px;
  color: #262d42 !important;
  display: flex;
}

.active{
  background-color: #39e489;
}

.disabled{
  cursor: not-allowed;
}
