.authContainer {
  .ant-carousel {
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    box-shadow: 4px 0px 35px rgba(0, 0, 0, 0.41);
    width: 50%; }
  .slick-dots.slick-dots-bottom {
    display:  none !important; } }
