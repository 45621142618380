$base-white-color: #fff;
$base-color: #00000000;
$shine-color: #ececec;
$animation-duration: 2s;

@mixin background-gradient {
  background-image: linear-gradient(90deg, $base-color 0, $shine-color 40px, $base-color 80px);
  background-size: 100%;
  animation-delay: 0s; }

@mixin white-background-gradient {
  background-image: linear-gradient(90deg, $base-white-color 0, $shine-color 40px, $base-white-color 80px) !important;
  background-size: 100%;
  animation-delay: 0s; }

.skeletonSvgContainer {
  position: relative;
  overflow: hidden;
  height: 100%;
  &.skeletonGraph, &.bgImg {
    position: absolute;
    height: 100%;
    background-size: cover;
    top: 0;
    left: 0;
    z-index: 2;
    background-position: center; }
  img {
    width: 100%;
    height: 100%; }
  .animation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    height: 100%;
    &.dashboardcard {
      @include background-gradient animation: shine-lines-dashboard-card 5s infinite ease-in-out;
      &.white {
        @include white-background-gradient animation: shine-lines-dashboard-card 5s infinite ease-in-out; } }
    &.weathercard {
      background-image: linear-gradient(90deg, $base-color 0, $shine-color 40px, $base-color 80px);
      background-size: 100%;
      animation-delay: 0s;
      animation: shine-lines-weather 4s infinite ease-in-out; }
    &.dashboardweathercard {
      @include background-gradient animation: shine-lines-weather-card $animation-duration infinite ease-in-out; }
    &.skeletonGraph {
      @include background-gradient animation: shine-lines-graph $animation-duration infinite ease-in-out; }
    &.skeletonCalendar {
      @include background-gradient animation: shine-lines-calendar 3s infinite ease-in-out; } } }

@keyframes shine-lines-dashboard-card {
  0% {
    background-position: -40px; }

  100% {
    background-position: 800px; } }

@keyframes shine-lines-weather {
  0% {
    background-position: -40px; }

  100% {
    background-position: 1340px; } }

@keyframes shine-lines-weather-card {
  0% {
    background-position: -40px; }

  40%, 100% {
    background-position: 350px; } }

@keyframes shine-lines-graph {
  0% {
    background-position: -40px; }

  100% {
    background-position: 600px; } }

@keyframes shine-lines-calendar {
  0% {
    background-position: -40px; }

  100% {
    background-position: 750px; } }
