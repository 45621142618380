.InfoColabOT {
  .cardContent {
    margin-top: 20px;
    padding: 10px 30px 20px 30px;
  }
  .ant-table-tbody > tr > td {
    color: #262d42;
  }
  .Active {
    color: #36b37e;
    background: #ebf7f2;
    padding: 5px 10px;
    border-radius: 3px;
  }
  .In-Progress {
    color: #262d42;
  }
  .Finished {
    padding: 5px 10px;
    color: #0065ff;
    background: #e6f0ff;
    border-radius: 3px;
  }

  .contentBtn {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .left {
    display: flex;
  }
  .right {
    display: flex;
  }
  .btn-download,
  .btn-download:hover,
  .btn-download:active,
  .btn-download:focus {
    display: flex;
    justify-content: center;
    align-self: center;
    padding: 5px 10px 5px 10px;
    border-radius: 3px;
    border: 1px solid #00a0ff;
  }
  .ant-table-thead > tr > th {
    color: #262d42;
  }
  .tableBtnView,
  .tableBtnView:hover,
  .tableBtnView:active {
    border: 1px solid transparent !important;
    background: transparent !important;
  }
  .btnsActions {
    display: flex;
  }
}
