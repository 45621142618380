.ant-tooltip-inner {
  background: white !important;
  color: black !important;
}
.ant-tooltip-inner * {
  color: black !important;
}
.ant-tooltip-arrow-content {
  background-color: #fff !important;
}
