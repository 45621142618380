.menuCollapseDropdown {
  background-color: #f0f3f4 !important;
  border-radius: 5px;
}

.integrationButtons {
  background-color: #00000000;
  border: none !important;
  margin-bottom: 10px !important;
  padding: 0 !important;
  // .ant-collapse-header {
  //   padding: 0 !important;
  // }
  // .ant-collapse-arrow {
  //   top: 15px !important;
  //   right: 26px !important;
  //   // display: none !important;
  // }
  // .ant-collapse-content-box,
  // .ant-collapse-content.ant-collapse-content-active {
  //   padding: 0 !important;
  //   border: none !important;
  //   background-color: #00000000;
  // }
  // .ant-collapse-item {
  //   border: none !important;
  // }
}

.LapzoButton, .NavbarButton {
  // border-radius: 4.7px;
  width: 220px;
  height: 40px;
  // padding: 12px;
  background-color: #fff;
  padding: 0 14px;
  padding-left: 14px !important;
  margin: 0 auto;
  align-items: center;
  &.green {
    &.main {
      border: 1px solid #63d778 !important;
      img {
        height: 23px !important;
      }
    }
    span {
      color: #63d778 !important;
    }
  }
  &.purple {
    // border: 1px solid #695ee8;
    border: none;
    span {
      color: #695ee8 !important;
    }
  }
  img {
    height: 17px;
  }
  span {
    font-size: 14px;
    font-weight: 400;
  }
}

ul.ant-menu.ant-menu-root.ant-menu-inline {
  padding-bottom: 80px;
}

.ant-tooltip {
  .ant-tooltip-inner {
    a
    // [style*="margin-left: 24px;"]
 {
      margin-left: 4px !important;
    }
  }
}

.ant-menu-submenu-popup.ant-menu.ant-menu-light.ant-menu-submenu-placement-rightTop {
  border-radius: 4px !important;
  a {
    margin-left: 7px !important;
    margin-right: 13px !important;
  }
}

.ant-menu {
  .ant-menu-title-content {
    a,
    .title {
      margin-left: 24px;
    }
  }
  .ant-menu-sub {
    .ant-menu-item-only-child {
      a {
        img {
          left: 24px !important;
        }
        margin-left: 21px !important;
        margin-bottom: -2px;
      }
    }
  }
  .AbsencesSubMenu {
    padding-left: 22px;
    @media (max-width: 750px) {
      padding-left: 0 !important;
      .ant-menu-title-content {
        overflow: visible !important;
      }
      img {
        top: 10px !important;
        left: -26px !important;
      }
    }
    .ant-menu-title-content {
      position: relative;
      left: 43px;
    }
    span {
      color: #262d42;
    }
    // .ant-menu-title-content a{
    //   // margin-left: 50px !important;
    //   position: relative;
    //   left: 50px;
    // }
    &.ant-menu-submenu-active {
      .ant-menu-submenu-title,
      .ant-menu-submenu-title:hover {
        background: #0000 !important;
      }
    }
    // i{
    //   display: none;
    // }
    img {
      left: -30px;
      top: 0;
      position: absolute;
    }
    .ant-menu-item-only-child {
      padding-left: 30px !important;
    }
    &.collapsedMenu {
      img {
        left: -82px;
        top: 0;
        position: relative;
      }
      .ant-menu-title-content {
        overflow: visible !important;
        position: relative;
        left: 0;
      }
    }
  }
}

.menuleft-dashboard {
  @media (max-width: 920px) {
    .ant-menu .AbsencesSubMenu span {
      margin-left: 50px !important;
    }
    .ant-menu .AbsencesSubMenu img {
      left: 25px !important;
    }
  }
}
