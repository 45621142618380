.InputsWapper {
  .anticon-right.ant-collapse-arrow {
    top: 21px !important;
  }
}

.LinesGraph {
  .ant-select {
    width: 105px;
    height: 30px !important;
    margin-top: 14px;
    margin-right: 25px;
    .ant-select-selector {
      height: 30px !important;
      color: #a1acc2;
      font-size: 14px !important;
      .ant-select-selection-item {
        font-size: 14px !important;
      }
    }
    .ant-select-arrow {
      top: 17px !important;
    }
  }
}

.Panel {
  .anticon-right.ant-collapse-arrow {
    display: none !important;
  }
  .ant-collapse-item {
    border: none !important;
  }
  border: none !important;
  box-shadow: 0 6px 6px rgba(9, 14, 37, 0.05) !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.lazy-loading {
  opacity: 0;
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  width: 80% !important;
  object-fit: cover;
}

.loading {
  position: absolute;
  z-index: 1;
  top: 9px;
  right: 0;
  left: 0;
}

.relative {
  position: relative;
}

.tabs-container {
  & .tabs {
    .tab {
      background: #cad7e6 !important;
      color: #fff !important;
      width: 154px;
      height: 42px;
      font-size: 17px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      font-weight: 500;
      margin-right: 10px;
      cursor: pointer;
      transition: all ease-in-out 0.3s;
      &.active {
        color: #39e489 !important;
        background: #fff !important;
      }
    }
  }

  & .card {
    box-shadow: none !important;
    border: none !important;
  }

  & .filter {
    width: 240px;
    height: 40px;
  }

  & .ant-select-item-option-content {
    padding-left: 15px !important;
  }

  & .ant-select-disabled {
    .ant-select-selector:hover {
      border: none !important;
    }
  }
}
